<template>
  <ManagerEditForm :is-profile="false" />
</template>

<script>
import ManagerEditForm from '@/components/organisms/ManagerEditForm.vue'

export default {
  components: {
    ManagerEditForm
  }
}
</script>
